@layer tailwind-base, primevue, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;

  /* Elements */
  body {
    -ms-overflow-style: none;
    /* Hide scrollbar in Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
  }

  body::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in Chrome */
  }

  input.hide-input {
    display: none;
  }

  /* Primevue */
  [data-pc-name="calendar"]>i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    padding-left: 0.5rem;

  }

  .p-input-icon-left,
  .p-input-icon-right {
    position: relative;
    display: inline-block;
  }

  .p-input-icon-left>i,
  .p-input-icon-right>i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    padding-left: 0.5rem;
  }

  .p-input-icon-left>input,
  .p-input-icon-left>textarea,
  .p-input-icon-left>[data-pc-name="dropdown"],
  .p-input-icon-left>[data-pc-name="calendar"],
  .p-input-icon-left>[data-pc-name="password"]>input {
    padding-left: 2rem;
  }

  .p-input-icon-right>input {
    padding-right: 2rem;
  }

  .p-input-icon-right>i:last-of-type {
    right: 0.75rem;
    color: #6b7280;
  }

  .p-toast-center {
    min-width: 20vw;
    transform: translate(-50%, -50%);
  }

  .p-toast-top-center {
    transform: translateX(-50%);
  }

  .p-toast-bottom-center {
    transform: translateX(-50%);
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  /* Other classes */
  .backdrop {
    font-family: "Poppins", "Roboto", sans-serif !important;
    background-size: cover !important;
    background-position-y: center !important;
  }

  .backdrop.no-background-image {
    background-size: inherit !important;
  }

  .classic-anchor {
    text-decoration: underline;
    color: hsl(var(--twc-primary));
    cursor: pointer;
  }

  .side-panel {
    width: 500px;
  }

  .side-panel.animated {
    animation-duration: 1s;
    animation-name: loginAnimation;
    animation-timing-function: ease-in-out;
  }

  .splitline {
    overflow: hidden;
    text-align: center !important;
  }

  .splitline:before,
  .splitline:after {
    background-color: hsl(var(--twc-textOnBackground));
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  .splitline:before {
    right: 0.4em;
    margin-left: -50%;
  }

  .splitline:after {
    left: 0.4em;
    margin-right: -50%;
  }

  /* Animations */
  @keyframes loginAnimation {
    from {
      right: -500px;
    }

    to {
      right: 0;
    }
  }

  /* Media queries */
  @media screen and (max-width: 600px) {
    .side-panel {
      width: auto;
    }

    .side-panel.animated {
      right: 0;
      animation-name: none;
    }

    .backdrop {
      background-image: inherit !important;
      background-size: inherit !important;
    }
  }

  @media screen and (min-width: 600px) and (min-height: 770px) {

    html,
    body {
      overflow-y: hidden;
    }
  }

  @media screen and (max-width: 600px),
  (max-height: 770px) {
    .main-content {
      height: auto;
    }
  }

  @media print {

    input[type=submit],
    input[type=button] {
      display: none !important;
    }

    .noprint {
      display: none !important;
    }
  }
}